import { AppearanceSettings } from '@myneva-portals/projects/src/components';
import {
  RestUsersService,
  IAppearanceSettingsTemplate,
  AppearanceSettingsTemplateRequest,
} from '@myneva-portals/services/src/services';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { navigationSelector } from '../../state/reducers/navigation';
import breadcrumbsUtils from '@myneva-portals/utils/src/utils/breadcrumbs-utils';
import {
  defaultErrorToast,
  redirectErrorToast,
} from '../../components/sections/static-data/default-error-toast';
import { showToast } from 'front-components/src/components';
import { useQuery, useMutation } from 'react-query';
import { useDropzone } from 'react-dropzone';
import { LoadingWrapper } from 'front-components/src/components';

export const AppearanceSettingsView: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const toastConfig = defaultErrorToast();
  const redirectToastConfig = redirectErrorToast();

  const [isSessionError, setIsSessionError] = React.useState(false);

  const url = process.env.WEB_GATEWAY_URL + '/core/settings/';
  const userService = new RestUsersService(url);
  const { breadcrumbs } = useSelector(navigationSelector);

  const customBreadCrumbConfig = [
    {
      breadcrumbName: t('APPEARANCE_SETTINGS'),
      path: '/appearance-settings',
    },
  ];

  const appearanceSettingsTemplateData = useQuery(
    'getAppearanceSettingsQuery',
    () =>
      userService
        .getAppearanceSettings()
        .then((data: IAppearanceSettingsTemplate[]) => data),
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  const { mutate: addOrEditAppearanceSettingsTemplate } = useMutation(
    (template: IAppearanceSettingsTemplate[]) =>
      userService.addOrEditAppearanceSettingsTemplate(template).then(() => {
        appearanceSettingsTemplateData.refetch();
      }),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          setIsSessionError(true);
          showToast(redirectToastConfig);
        } else {
          showToast(toastConfig);
        }
      },
    }
  );

  return (
    <div>
      <LoadingWrapper
        isLoading={appearanceSettingsTemplateData.isLoading || isSessionError}
        content={
          <>
            <AppearanceSettings
              translation={t}
              breadcrumbs={breadcrumbsUtils.getBreadcrumbs(
                breadcrumbs,
                customBreadCrumbConfig
              )}
              appearanceSettingsTemplateData={
                appearanceSettingsTemplateData.data
              }
              addOrEditAppearanceSettingsTemplate={
                addOrEditAppearanceSettingsTemplate
              }
              useDropzone={useDropzone}
            />
          </>
        }
        useLineProgress={true}
      />
    </div>
  );
};
