import { BreadcrumbsItem } from '@myneva-portals/projects/src/components';
import PageKey from '@myneva-portals/models/src/models/page-key';

interface ICustomBreadcrumb {
  breadcrumbName: string;
  path: string;
}

class BreadcrumbsUtils {
  getPageName(key: PageKey): string {
    return key.replace('/', '').toUpperCase();
  }

  getBreadcrumbs = (
    breadcrumbs: PageKey[],
    customBreadcrumbList?: ICustomBreadcrumb[]
  ): BreadcrumbsItem[] => {
    return breadcrumbs.map((path) => {
      const breadcrumbItem: ICustomBreadcrumb | undefined =
        customBreadcrumbList?.find(
          (breadcrumb: ICustomBreadcrumb) => breadcrumb.path === path
        );
      return {
        name: breadcrumbItem?.breadcrumbName || this.getPageName(path),
        path,
        isToTranslate: !breadcrumbItem,
      };
    });
  };
}

const breadcrumbsUtils = new BreadcrumbsUtils();
export default breadcrumbsUtils;
